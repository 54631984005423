import React from 'react'
import './seller.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar ,faCrown,faStarHalf } from '@fortawesome/free-solid-svg-icons'
function Seller() {

  return (
    <>
<div className='seller' >
        <div className="title">الأكثر مبيعا <FontAwesomeIcon icon={faCrown}/></div>
      <div className='seller-container'>
      <div div="false" className="best-seller">
          <div div="false" className="seller-img"><img src='https://de.cdn-website.com/598e1ba3699c41a3b95bf086e9b958ae/dms3rep/multi/Valkyrie_Flat_Right_Angle_10K_V2-81093200.webp' alt="" /></div>
          <div div="false"  className='text'>
              <div div="false" className="heading"><h3>سرينه ٢٢٠ فولت</h3></div>
              <div div="false" className="paragraph">سرينه ٢٢٠ فولت</div>
              <div div="false" className="rating"><FontAwesomeIcon icon={faStar}/><FontAwesomeIcon icon={faStar}/><FontAwesomeIcon icon={faStar}/><FontAwesomeIcon icon={faStar}/><FontAwesomeIcon icon={faStar}/></div>
          </div>
        </div>
      <div div="false" className="best-seller">
          <div div="false" className="seller-img"><img src='https://le-de.cdn-website.com/598e1ba3699c41a3b95bf086e9b958ae/dms3rep/multi/opt/02-Reacton-Products-Accessories-Manual-Actuator-03-1920w.jpg' alt="" /></div>
          <div div="false"  className='text'>
              <div div="false" className="heading"><h3>جهاز إطفاء حريق بودرة تلقائى 6 كجم</h3></div>
              <div div="false" className="paragraph">جهـاز إطفاء موضعى يعمل تلقائيا وبدون تدخل بشرى ميزات الجهاز</div>
              <div div="false" className="rating"><FontAwesomeIcon icon={faStar}/><FontAwesomeIcon icon={faStar}/><FontAwesomeIcon icon={faStar}/><FontAwesomeIcon icon={faStar}/><FontAwesomeIcon icon={faStar}/></div>
          </div>
        </div>
      <div div="false" className="best-seller">
          <div div="false" className="seller-img"><img src='https://le-de.cdn-website.com/598e1ba3699c41a3b95bf086e9b958ae/dms3rep/multi/opt/ldc_piped-1920w.png' alt="" /></div>
          <div div="false"  className='text'>
              <div div="false" className="heading"><h3>
جهاز إطفاء حريق يدوى يعمل بامل سحوق الكيماوى </h3></div>
              <div div="false" className="paragraph">ذو قدرة إطفائية عالية الأمثل للإستخدام في السيارات مقبض الجهاز مناسب قبضة اليد مما يحقق سهولة التحكم عند الإستخدام </div>
              <div div="false" className="rating"><FontAwesomeIcon icon={faStar}/><FontAwesomeIcon icon={faStar}/><FontAwesomeIcon icon={faStar}/><FontAwesomeIcon icon={faStar}/><FontAwesomeIcon icon={faStar}/></div>
          </div>
        </div>
      <div div="false" className="best-seller">
          <div div="false" className="seller-img"><img src='https://le-de.cdn-website.com/598e1ba3699c41a3b95bf086e9b958ae/dms3rep/multi/opt/foam_hardware_discharge_monitor-1920w.png' alt="" /></div>
          <div div="false"  className='text'>
              <div div="false" className="heading"><h3>كالون لوحه حريق معدن</h3></div>
              <div div="false" className="paragraph">كالون لوحه حريق معدن</div>
              <div div="false" className="rating"><FontAwesomeIcon icon={faStar}/><FontAwesomeIcon icon={faStar}/><FontAwesomeIcon icon={faStar}/><FontAwesomeIcon icon={faStar}/><FontAwesomeIcon icon={faStar}/></div>
          </div>
        </div>
      <div div="false" className="best-seller">
          <div div="false" className="seller-img"><img src='https://le-de.cdn-website.com/598e1ba3699c41a3b95bf086e9b958ae/dms3rep/multi/opt/foam_hardware_horz_bladder_page-1920w.png' alt="" /></div>
          <div div="false"  className='text'>
              <div div="false" className="heading"><h3>جهاز أطفاء حريق بودره جافه سعه 50 كج أقتحام على شاسيه عجل
</h3></div>
              <div div="false" className="paragraph">جهاز أطفاء حريق بودره جافه سعه 50 كج أقتحام على شاسيه عجل
</div>
              <div div="false" className="rating"><FontAwesomeIcon icon={faStar}/><FontAwesomeIcon icon={faStar}/><FontAwesomeIcon icon={faStar}/><FontAwesomeIcon icon={faStar}/><FontAwesomeIcon icon={faStar}/></div>
          </div>
        </div>
      </div>
</div>  
  </>
  )
}

export default Seller