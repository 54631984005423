import './App.css';
import Home from './component/content/index';
import {Routes , Route} from "react-router-dom"
import Johnson_controls from './component/content/company/johnson-controls/johnson-controls';
function App() {
  return (
    <div className="App">
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="johnson_controls" element={<Johnson_controls />} />
      </Routes>
    </div>
  );
}

export default App;
