import React from 'react'
import './slider.css';
import img from '../../imge/firefighting-2.jpg'
import img2 from '../../imge/a24b8f30-114c-48c4-85cc-585ca8ef2d05.png'
import img3 from '../../imge/Fire-Prevention-Equipment.webp'
import img4 from '../../imge/Content_Fire_Detection_Alarm_and_Suppression_System.jpg'
import img5 from '../../imge/انظمة-حماية-الكاميرات.webp'
const Slider = () => {
  return (
    <div id="carouselExampleInterval" class="carousel slide " data-bs-ride="carousel">
  <div class="carousel-inner" style={{height:"700px"}}>
    <div class="carousel-item active" style={{height:"700px"}} data-bs-interval="10000">
      <img src={img} class="d-block w-100" alt="..." style={{height:"700px"}}/>
    </div>
    <div class="carousel-item" data-bs-interval="2000">
      <img src={img2} class="d-block w-100" alt="..." style={{height:"700px"}}/>
    </div>
    <div class="carousel-item">
      <img src={img3} class="d-block w-100" alt="..." style={{height:"700px"}}/>
    </div>
    <div class="carousel-item">
      <img src={img4} class="d-block w-100" alt="..." style={{height:"700px"}}/>
    </div>
    <div class="carousel-item">
      <img src={img5} class="d-block w-100" alt="..." style={{height:"700px"}}/>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
  <div className="comp-text">
    <h1>Connex L.T.d</h1>
    <p>International Engineering <br/> & <br/> Trading Company</p>
  </div>
</div>
  )
}

export default Slider