import React from 'react'
import Logo from '../../../imge/CONNEX-LOGO-8a15426a-108w.webp'
import './whous.css';
import { Link } from 'react-router-dom';
const WhoUs = () => {
  return (
    <div class="container text-center">
  <div class="row">
    <div class="col-12">
      <div className='heading'>
        <h1>من نحن</h1>
        {/* <h5>Connex L.T.d</h5> */}
        <img src={Logo} alt="" />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
    <div className="info">
        <ul>
          <li className='text-sm-end'>تعتبر شركة كونكس شريكًا موثوقًا لجميع إحتياجات الأمن والسلامة الخاصة بالشركات والمجتمعات في تصاميمها وتوريدها وتركيبها وصيانتها</li>
          <li className='text-sm-end'>تتميز شركة كونكس بجودة خدماتها واحترافيتها في تصميم وتركيب الأنظمة الأمنية الحديثة والأعمال الكهروميكانيكية المختلفة وجميع الأعمال الكهربائية</li>
          <li className='text-sm-end'>تتميز [ Connex ] بتقديم مجموعة متنوعة من المنتجات والخدمات التي تضمن الحماية الشاملة للمنشآت والأفراد. </li>
          <li className='text-sm-end'>تقدم [ Connex ] أحدث التقنيات والحلول التي تلبي متطلبات السوق وتفوق توقعات العملاء.</li>
          <li className='text-sm-end'>مع مجموعة متنوعة من المنتجات التي تشمل أنظمة المراقبة بالفيديو، وأنظمة الإنذار والتنبيه، وأنظمة إطفاء الحرائق،</li>
          <li className='text-sm-end'>باختصار، [ Connex ] هي الشريك الموثوق الذي يوفر حلولًا متكاملة وموثوقة في مجالات الأمان والمراقبة والإطفاء،</li>
        </ul>
      </div>
    </div>
  </div>
</div>
  )
}

export default WhoUs