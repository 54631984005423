import React from 'react'
import Nav from '../nav/nav'
import Slider from '../slider/slider'
import WhoUs from './who us/whous'
import Comp from './comp/comp'
import BestSaller from './best seller/seller'
const Home = () => {
  return (
    <div>
      <Nav/>
      <Slider/>
      <BestSaller/>
      <WhoUs/>
      <Comp/>
    </div>
  )
}

export default Home