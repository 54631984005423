import React from 'react'
import Nav from '../../../nav/nav'
import './johnson-controls.css';
const johnson_controls = () => {
  return (
<>
  <Nav/>
    <div>
    <div className="section one">
      <div className="img">
        <img src="https://mepcokuwait.com/wp-content/uploads/2019/03/shutterstock_1095810413-2140x1200.jpg" alt="" />
      </div>
      <div className="section_text one">
        <h1>قم بتحسين نظام التدفئة والتهوية وتكييف الهواء لديك</h1>
      <button className='btn btn-primary'>معرفة المزيد</button>
      </div>
      <div className="section_category">معدات التدفئة والتهوية وتكييف الهواء</div>
      <div className="section_logo"><img src="https://le-de.cdn-website.com/598e1ba3699c41a3b95bf086e9b958ae/dms3rep/multi/opt/johnson-controls-1aa4dc28-1920w.png" alt="" /></div>
    </div>

    <div className="section two">
      <div className="img">
        <img src="https://www.thehousedesigners.com/images/plans/URD/bulk/1747/the-journey1_m.jpg" alt="" />
      </div>
      <div className="section_text two">
        <h1>تتطلب المباني الذكية أدوات تحكم ذكية: حلول ذكية لكل منشأة</h1>
      <button className='btn btn-primary'>معرفة المزيد</button>
      </div>
      <div className="section_category">أتمتة البناء والتحكم</div>
      <div className="section_logo"><img src="https://le-de.cdn-website.com/598e1ba3699c41a3b95bf086e9b958ae/dms3rep/multi/opt/johnson-controls-1aa4dc28-1920w.png" alt="" /></div>
    </div>

    <div className="section three">
      <div className="img">
        <img src="https://img.freepik.com/premium-photo/security-camera-modern-building-security-concept-generative-aixa_159242-30467.jpg?w=2000" alt="" />
      </div>
      <div className="section_text two">
        <h1>حلول شاملة لحماية الأشخاص والمرافق والأصول</h1>
      <button className='btn btn-primary'>معرفة المزيد</button>
      </div>
      <div className="section_category">حماية</div>
      <div className="section_logo"><img src="https://le-de.cdn-website.com/598e1ba3699c41a3b95bf086e9b958ae/dms3rep/multi/opt/johnson-controls-1aa4dc28-1920w.png" alt="" /></div>
    </div>

    <div className="section four">
      <div className="img">
        <img src="https://www.alertmedia.com/wp-content/uploads/2022/08/AM-Podcast-WebsiteGraphic-Childers-Hayes-Richards-v1.jpg" alt="" />
      </div>
      <div className="section_text two">
        <h1>حلول ومنتجات للمساعدة في حماية الأشخاص والمرافق</h1>
      <button className='btn btn-primary'>معرفة المزيد</button>
      </div>
      <div className="section_category">الكشف عن الحرائق</div>
      <div className="section_logo"><img src="https://le-de.cdn-website.com/598e1ba3699c41a3b95bf086e9b958ae/dms3rep/multi/opt/johnson-controls-1aa4dc28-1920w.png" alt="" /></div>
    </div>

    <div className="section five">
      <div className="img">
        <img src="https://www.brayarch.com/wp-content/uploads/2022/05/E_Delafield_Public-Safety-Building_Fire-Truck-Bays-2-1440x960.jpg" alt="" />
      </div>
      <div className="section_text two">
        <h1>المساعدة في إنقاذ الأرواح وحماية الممتلكات </h1>
      <button className='btn btn-primary'>معرفة المزيد</button>
      </div>
      <div className="section_category">إخماد الحرائق</div>
      <div className="section_logo"><img src="https://le-de.cdn-website.com/598e1ba3699c41a3b95bf086e9b958ae/dms3rep/multi/opt/johnson-controls-1aa4dc28-1920w.png" alt="" /></div>
    </div>

    <div className="section six">
      <div className="img">
        <img src="https://image.made-in-china.com/2f0j00IkoWSgDEsBUR/H225m-Water-Long-Shaft-Cooling-Motor.webp" alt="" />
      </div>
      <div className="section_text two">
        <h1>التبريد الصناعي: الحفاظ على برودة الأغذية والعمليات على نطاق واسع </h1>
      <button className='btn btn-primary'>معرفة المزيد</button>
      </div>
      <div className="section_category">التبريد الصناعي</div>
      <div className="section_logo"><img src="https://le-de.cdn-website.com/598e1ba3699c41a3b95bf086e9b958ae/dms3rep/multi/opt/johnson-controls-1aa4dc28-1920w.png" alt="" /></div>
    </div>

    <div className="section seven">
      <div className="img">
        <img src="https://solarabic.com/wp-content/uploads/2022/06/why-you-should-choose-a-rooftop-pv-system.jpg" alt="" />
      </div>
      <div className="section_text two">
        <h1>تخزين الطاقة الموزعة: نهج أكثر ذكاءً للطاقة </h1>
      <button className='btn btn-primary'>معرفة المزيد</button>
      </div>
      <div className="section_category">تخزين الطاقة الموزعة</div>
      <div className="section_logo"><img src="https://le-de.cdn-website.com/598e1ba3699c41a3b95bf086e9b958ae/dms3rep/multi/opt/johnson-controls-1aa4dc28-1920w.png" alt="" /></div>
    </div>

    </div>
</>
  )
}

export default johnson_controls